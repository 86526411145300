const data = {
  menuItems: [
    {
      label: 'Our Services',
      path: '#services',
      offset: '100',
    },
    {
      label: 'Control Remotely',
      path: '#control',
      offset: '100',
    },
    {
      label: 'Key Features',
      path: '#keyfeature',
      offset: '0',
    },
    {
      label: 'Partners',
      path: '#partners',
      offset: '-100',
    },
    {
      label: 'Payments',
      path: '#payments',
      offset: '100',
    },
    {
      label: 'Testimonial',
      path: '#testimonialSection',
      offset: '100',
    },
  ],
};
export default data;
